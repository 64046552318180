import * as React from "react"
import { SVGProps } from "react"

const YoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		viewBox="0 0 77 54"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M71.1624 9.59144C70.3699 6.61443 68.6324 4.09464 65.6554 3.30213C60.2602 1.85935 38.1207 1.72726 38.1207 1.72726C38.1207 1.72726 15.9811 1.85935 10.5859 3.30213C7.60889 4.09464 5.88161 6.61443 5.07894 9.59144C3.63616 14.9866 3.5752 26.1123 3.5752 26.1123C3.5752 26.1123 3.63616 37.238 5.07894 42.6331C5.87145 45.6102 7.60889 48.1299 10.5859 48.9225C15.9811 50.3652 38.1207 50.4973 38.1207 50.4973C38.1207 50.4973 60.2602 50.3652 65.6554 48.9225C68.6324 48.1299 70.3699 45.6102 71.1624 42.6331C72.6051 37.238 72.6661 26.1123 72.6661 26.1123C72.6661 26.1123 72.6051 14.9866 71.1624 9.59144Z"
			fill="#FF0000"
		/>
		<path d="M49.3706 26.112L30.3252 15.5312V36.6928" fill="white" />
	</svg>
)

export default YoutubeIcon
