import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"
import YoutubeIcon from "../../../assets/_V2/svg/YoutubeIcon"

interface Webinar {
	id: number
	title: string
	description: JSX.Element | string
	date: string
	link: string
	img: JSX.Element
	speaker: {
		photo: JSX.Element
		name: string
		about: string
	}

	past: boolean
}

interface Props {
	className?: string
	data: Webinar[]
}

export default function Webinars(props: Props) {
	const webinarsEl = useRef()
	const showMoreBtn = useRef()

	const [perPage, setPerPage] = useState(9)
	const [isPending, setIsPending] = useState(false)
	const [isEndReached, setIsEndReached] = useState(false)
	const [webinarsCount, setWebinarsCount] = useState(perPage)

	const showMore = () => {
		if (isEndReached || isPending) return

		setIsPending(true)

		setTimeout(() => {
			setIsPending(false)

			const newCount = webinarsCount + perPage

			setWebinarsCount(newCount)

			if (newCount >= props.data.length) {
				setIsEndReached(true)
			}
		}, 1000)
	}

	useEffect(() => {
		if (
			window.matchMedia("screen and (max-width: 1319px) and (min-width: 769px)")
				.matches
		) {
			setPerPage(8)
			setWebinarsCount(8)
		}

		const onScrollWindow = () => {
			if (
				webinarsEl.current.getBoundingClientRect().bottom - window.innerHeight <
				0
			) {
				showMoreBtn.current.click()
			}
		}

		window.addEventListener("scroll", onScrollWindow)

		return () => window.removeEventListener("scroll", onScrollWindow)
	}, [])

	const renderWebinar = (webinar: Webinar) => (
		<article
			key={webinar.id}
			className={classNames(styles.webinar, styles.webinars__item)}
		>
			<figure className={styles.webinar__img}>
				{webinar.img}

				<div className={styles.webinar__overlay}>
					{webinar.past ? (
						<a
							href={webinar.link}
							target={"_blank"}
							className={classNames(
								styles.webinar__overlayLink,
								styles.webinar__overlayLinkPast
							)}
						>
							<YoutubeIcon />
						</a>
					) : (
						<a
							href={webinar.link}
							target={"_blank"}
							className={classNames(
								styles.webinar__overlayLink,
								styles.webinar__overlayLinkActual
							)}
						>
							Зарегистрироваться
						</a>
					)}
				</div>
			</figure>

			<div className={styles.webinar__content}>
				<span
					className={classNames(
						styles.webinar__date,
						webinar.past ? styles.webinar__datePast : styles.webinar__dateActual
					)}
				>
					{webinar.date}
				</span>

				<h3 className={styles.webinar__title}>{webinar.title}</h3>

				<div className={styles.webinar__description}>{webinar.description}</div>

				<div className={styles.webinar__speaker}>
					<div className={styles.webinar__speakerPhoto}>
						{webinar.speaker.photo}
					</div>

					<div className={styles.webinar__speakerContent}>
						<h5 className={styles.webinar__speakerName}>
							{webinar.speaker.name}
						</h5>

						<span className={styles.webinar__speakerAbout}>
							{webinar.speaker.about}
						</span>
					</div>
				</div>
			</div>
		</article>
	)

	return (
		<>
			<section
				className={classNames(styles.webinars, props.className)}
				ref={webinarsEl}
			>
				{props.data
					.slice(0, webinarsCount)
					.map((webinar: Webinar) => renderWebinar(webinar))}

				<div
					className={classNames(styles.webinars__loader, {
						[styles.webinars__loaderShowed]: isPending,
					})}
				/>
			</section>

			<button
				className={styles.webinars__btn}
				ref={showMoreBtn}
				onClick={showMore}
			>
				Показать еще
			</button>
		</>
	)
}
