import React from "react"
import classNames from "classnames"

import DefaultLayout from "../layout/default"

import CtaForm from "../pagesSections/index/CtaForm"
import Headline from "../pagesSections/webinars/headline"

import pageStyles from "../styles/_V2/pagesStyles/styles.module.scss"
import styles from "./styles/_V2/webinars.module.scss"
import Webinars from "../components/_V2/Webinars"
import webinars from "../pages-data/_V2/webinars/webinars"

export default function PageCoffeehouse() {
	const metaTags = [
		// {
		//   name: 'keywords',
		//   content: 'quick resto, автоматизация кофейни, ресторанный бизнес, программа учёта, склад, общепит',
		// },
		{
			name: "description",
			content:
				"Вебинары для рестораторов и начинающих предпринимателей: обсуждаем открытие и развитие проектов в ресторанной индустрии. Рассказываем про тренды, лайфхаки и делимся полезной информацией по автоматизации предприятий общественного питания. Смотрите вебинары в записи или регистрируйтесь на предстоящие мероприятия.",
		},
		{
			property: "og:url",
			content: "https://quickresto.ru/webinars/",
		},
		{
			property: "og:title",
			content: "Расписание вебинаров про ресторанный бизнес от Quick Resto",
		},
		{
			property: "og:description",
			content:
				"Вебинары для рестораторов и начинающих предпринимателей: обсуждаем открытие и развитие проектов в ресторанной индустрии. Рассказываем про тренды, лайфхаки и делимся полезной информацией по автоматизации предприятий общественного питания. Смотрите вебинары в записи или регистрируйтесь на предстоящие мероприятия.",
		},
		{
			property: "og:type",
			content: "website",
		},
	]

	return (
		<DefaultLayout
			title="Расписание вебинаров про ресторанный бизнес от Quick Resto"
			metaTags={metaTags}
			mainWrapperClassName={classNames(
				pageStyles.pageWrapper,
				pageStyles.pageGray,
				styles.webinars
			)}
			linkCanonical="https://quickresto.ru/webinars/"
			footerClassName={pageStyles.pageSection}
		>
			<Headline className={styles.webinars__headline} />

			<Webinars data={webinars} className={styles.webinars__webinars} />

			<CtaForm isWhiteBackground className={pageStyles.pageSection} />
		</DefaultLayout>
	)
}
