import React from "react"
import styles from "./styles.module.scss"
import data from "./data"
import classNames from "classnames"

interface Props {
	className?: string
}

export default function Headline(props: Props) {
	return (
		<section className={classNames(styles.headline, props.className)}>
			<span className={styles.headline__pageName}>{data.pageName}</span>
			<h1 className={styles.headline__title}>{data.title}</h1>

			{data.backdrop}
		</section>
	)
}
