import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default [
	{
		id: 1,
		title: "Финансовая грамотность ресторатора",
		description: (
			<p>
				Вебинар для тех, кто хочет узнать, как грамотно вести финансовый учет в
				кафе или ресторане. Поговорим о самых важных отчетах и показателях.
			</p>
		),
		date: "12 сентября, 18:00",
		link: "https://myquickresto.ru/webinar",

		img: (
			<StaticImage
				src="./assets/webinars-1.png"
				alt="Финансовая грамотность ресторатора"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-1.png"
					alt="Алексей Гаврилов"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Алексей Гаврилов",
			about: "Амбассадор бренда Quick Resto, досконально знает всю систему...",
		},

		past: false,
	},
	{
		id: 2,
		title: "Как вести учет в формате заведения Dark Kitchen",
		description: (
			<p>
				Вебинар для тех, кто хочет узнать, как правильно вести учет в форматах
				продажи еды на доставку и навынос. На&nbsp;примере работающего
				бизнеса...
			</p>
		),
		date: "9 сентября, 18:00",
		link: "https://myquickresto.ru/webinar",

		img: (
			<StaticImage
				src="./assets/webinars-2.png"
				alt="Как вести учет в формате заведения Dark Kitchen"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-2.png"
					alt="Светлана Ивановна"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Светлана Ивановна",
			about: "Амбассадор бренда Quick Resto, ресторатор...",
		},

		past: false,
	},
	{
		id: 3,
		title: "Как вести складской учет в ресторане",
		description: (
			<p>
				На вебинаре расскажем, как правильно принимать поставки, контролировать
				расходы и планировать закупки. Разберем весь процесс автоматизации...
			</p>
		),
		date: "6 сентября, 18:00",
		link: "https://myquickresto.ru/webinar",

		img: (
			<StaticImage
				src="./assets/webinars-3.png"
				alt="Как вести складской учет в ресторане"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-3.png"
					alt="Владимир Константинович"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Владимир Константинович",
			about: "Амбассадор бренда Quick Resto, ресторатор...",
		},

		past: false,
	},
	{
		id: 4,
		title: "Финансовая грамотность ресторатора",
		description: (
			<p>
				Вебинар для тех, кто хочет узнать, как грамотно вести финансовый учет в
				кафе или ресторане. Поговорим о самых важных отчетах и показателях.
			</p>
		),
		date: "Сегодня, 18:00",
		link: "https://myquickresto.ru/webinar",

		img: (
			<StaticImage
				src="./assets/webinars-1.png"
				alt="Финансовая грамотность ресторатора"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-4.png"
					alt="Омар Арабский"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Омар Арабский",
			about: "Амбассадор бренда Quick Resto, досконально знает всю систему...",
		},

		past: false,
	},
	{
		id: 5,
		title: "Как вести учет в формате заведения Dark Kitchen",
		description: (
			<p>
				Вебинар для тех, кто хочет узнать, как правильно вести учет в форматах
				продажи еды на доставку и навынос. На&nbsp;примере работающего
				бизнеса...
			</p>
		),
		date: "Завершен 25 августа",
		link: "https://www.youtube.com/watch?v=uvg_5gcTCjM",

		img: (
			<StaticImage
				src="./assets/webinars-2.png"
				alt="Как вести учет в формате заведения Dark Kitchen"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-5.png"
					alt="Валентина Марковна"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Валентина Марковна",
			about: "Амбассадор бренда Quick Resto, ресторатор...",
		},

		past: true,
	},
	{
		id: 6,
		title: "Как вести складской учет в ресторане",
		description: (
			<p>
				На вебинаре расскажем, как правильно принимать поставки, контролировать
				расходы и планировать закупки. Разберем весь процесс автоматизации...
			</p>
		),
		date: "Завершен 1 мая 2021",
		link: "https://www.youtube.com/watch?v=uvg_5gcTCjM",

		img: (
			<StaticImage
				src="./assets/webinars-3.png"
				alt="Как вести складской учет в ресторане"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-6.png"
					alt="Эдуард Привольный"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Эдуард Привольный",
			about: "Амбассадор бренда Quick Resto, ресторатор...",
		},

		past: true,
	},
	{
		id: 7,
		title: "Финансовая грамотность ресторатора",
		description: (
			<p>
				Вебинар для тех, кто хочет узнать, как грамотно вести финансовый учет в
				кафе или ресторане. Поговорим о самых важных отчетах и показателях.
			</p>
		),
		date: "Завершен 1 апреля 2021",
		link: "https://www.youtube.com/watch?v=uvg_5gcTCjM",

		img: (
			<StaticImage
				src="./assets/webinars-1.png"
				alt="Финансовая грамотность ресторатора"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-4.png"
					alt="Омар Арабский"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Омар Арабский",
			about: "Амбассадор бренда Quick Resto, досконально знает всю систему...",
		},

		past: true,
	},
	{
		id: 8,
		title: "Как вести учет в формате заведения Dark Kitchen",
		description: (
			<p>
				Вебинар для тех, кто хочет узнать, как правильно вести учет в форматах
				продажи еды на доставку и навынос. На&nbsp;примере работающего
				бизнеса...
			</p>
		),
		date: "Завершен 10 марта 2021",
		link: "https://www.youtube.com/watch?v=uvg_5gcTCjM",

		img: (
			<StaticImage
				src="./assets/webinars-2.png"
				alt="Как вести учет в формате заведения Dark Kitchen"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-5.png"
					alt="Валентина Марковна"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Валентина Марковна",
			about: "Амбассадор бренда Quick Resto, ресторатор...",
		},

		past: true,
	},
	{
		id: 9,
		title: "Как вести складской учет в ресторане",
		description: (
			<p>
				На вебинаре расскажем, как правильно принимать поставки, контролировать
				расходы и планировать закупки. Разберем весь процесс автоматизации...
			</p>
		),
		date: "Завершен 1 февраля 2021",
		link: "https://www.youtube.com/watch?v=uvg_5gcTCjM",

		img: (
			<StaticImage
				src="./assets/webinars-3.png"
				alt="Как вести складской учет в ресторане"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-6.png"
					alt="Эдуард Привольный"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Эдуард Привольный",
			about: "Амбассадор бренда Quick Resto, ресторатор...",
		},

		past: true,
	},
	{
		id: 10,
		title: "Финансовая грамотность ресторатора",
		description: (
			<p>
				Вебинар для тех, кто хочет узнать, как грамотно вести финансовый учет в
				кафе или ресторане. Поговорим о самых важных отчетах и показателях.
			</p>
		),
		date: "12 сентября, 18:00",
		link: "https://myquickresto.ru/webinar",

		img: (
			<StaticImage
				src="./assets/webinars-1.png"
				alt="Финансовая грамотность ресторатора"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-1.png"
					alt="Алексей Гаврилов"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Алексей Гаврилов",
			about: "Амбассадор бренда Quick Resto, досконально знает всю систему...",
		},

		past: false,
	},
	{
		id: 11,
		title: "Как вести учет в формате заведения Dark Kitchen",
		description: (
			<p>
				Вебинар для тех, кто хочет узнать, как правильно вести учет в форматах
				продажи еды на доставку и навынос. На&nbsp;примере работающего
				бизнеса...
			</p>
		),
		date: "9 сентября, 18:00",
		link: "https://myquickresto.ru/webinar",

		img: (
			<StaticImage
				src="./assets/webinars-2.png"
				alt="Как вести учет в формате заведения Dark Kitchen"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-2.png"
					alt="Светлана Ивановна"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Светлана Ивановна",
			about: "Амбассадор бренда Quick Resto, ресторатор...",
		},

		past: false,
	},
	{
		id: 12,
		title: "Как вести складской учет в ресторане",
		description: (
			<p>
				На вебинаре расскажем, как правильно принимать поставки, контролировать
				расходы и планировать закупки. Разберем весь процесс автоматизации...
			</p>
		),
		date: "6 сентября, 18:00",
		link: "https://myquickresto.ru/webinar",

		img: (
			<StaticImage
				src="./assets/webinars-3.png"
				alt="Как вести складской учет в ресторане"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-3.png"
					alt="Владимир Константинович"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Владимир Константинович",
			about: "Амбассадор бренда Quick Resto, ресторатор...",
		},

		past: false,
	},
	{
		id: 13,
		title: "Финансовая грамотность ресторатора",
		description: (
			<p>
				Вебинар для тех, кто хочет узнать, как грамотно вести финансовый учет в
				кафе или ресторане. Поговорим о самых важных отчетах и показателях.
			</p>
		),
		date: "Сегодня, 18:00",
		link: "https://myquickresto.ru/webinar",

		img: (
			<StaticImage
				src="./assets/webinars-1.png"
				alt="Финансовая грамотность ресторатора"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-4.png"
					alt="Омар Арабский"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Омар Арабский",
			about: "Амбассадор бренда Quick Resto, досконально знает всю систему...",
		},

		past: false,
	},
	{
		id: 14,
		title: "Как вести учет в формате заведения Dark Kitchen",
		description: (
			<p>
				Вебинар для тех, кто хочет узнать, как правильно вести учет в форматах
				продажи еды на доставку и навынос. На&nbsp;примере работающего
				бизнеса...
			</p>
		),
		date: "Завершен 25 августа",
		link: "https://www.youtube.com/watch?v=uvg_5gcTCjM",

		img: (
			<StaticImage
				src="./assets/webinars-2.png"
				alt="Как вести учет в формате заведения Dark Kitchen"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-5.png"
					alt="Валентина Марковна"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Валентина Марковна",
			about: "Амбассадор бренда Quick Resto, ресторатор...",
		},

		past: true,
	},
	{
		id: 15,
		title: "Как вести складской учет в ресторане",
		description: (
			<p>
				На вебинаре расскажем, как правильно принимать поставки, контролировать
				расходы и планировать закупки. Разберем весь процесс автоматизации...
			</p>
		),
		date: "Завершен 1 мая 2021",
		link: "https://www.youtube.com/watch?v=uvg_5gcTCjM",

		img: (
			<StaticImage
				src="./assets/webinars-3.png"
				alt="Как вести складской учет в ресторане"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-6.png"
					alt="Эдуард Привольный"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Эдуард Привольный",
			about: "Амбассадор бренда Quick Resto, ресторатор...",
		},

		past: true,
	},
	{
		id: 16,
		title: "Финансовая грамотность ресторатора",
		description: (
			<p>
				Вебинар для тех, кто хочет узнать, как грамотно вести финансовый учет в
				кафе или ресторане. Поговорим о самых важных отчетах и показателях.
			</p>
		),
		date: "Завершен 1 апреля 2021",
		link: "https://www.youtube.com/watch?v=uvg_5gcTCjM",

		img: (
			<StaticImage
				src="./assets/webinars-1.png"
				alt="Финансовая грамотность ресторатора"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-4.png"
					alt="Омар Арабский"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Омар Арабский",
			about: "Амбассадор бренда Quick Resto, досконально знает всю систему...",
		},

		past: true,
	},
	{
		id: 17,
		title: "Как вести учет в формате заведения Dark Kitchen",
		description: (
			<p>
				Вебинар для тех, кто хочет узнать, как правильно вести учет в форматах
				продажи еды на доставку и навынос. На&nbsp;примере работающего
				бизнеса...
			</p>
		),
		date: "Завершен 10 марта 2021",
		link: "https://www.youtube.com/watch?v=uvg_5gcTCjM",

		img: (
			<StaticImage
				src="./assets/webinars-2.png"
				alt="Как вести учет в формате заведения Dark Kitchen"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-5.png"
					alt="Валентина Марковна"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Валентина Марковна",
			about: "Амбассадор бренда Quick Resto, ресторатор...",
		},

		past: true,
	},
	{
		id: 18,
		title: "Как вести складской учет в ресторане",
		description: (
			<p>
				На вебинаре расскажем, как правильно принимать поставки, контролировать
				расходы и планировать закупки. Разберем весь процесс автоматизации...
			</p>
		),
		date: "Завершен 1 февраля 2021",
		link: "https://www.youtube.com/watch?v=uvg_5gcTCjM",

		img: (
			<StaticImage
				src="./assets/webinars-3.png"
				alt="Как вести складской учет в ресторане"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		speaker: {
			photo: (
				<StaticImage
					src="./assets/speaker-6.png"
					alt="Эдуард Привольный"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			name: "Эдуард Привольный",
			about: "Амбассадор бренда Quick Resto, ресторатор...",
		},

		past: true,
	},
]
